@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

.headerStyle {
  display: flex;
  flex-direction: row;
  background-color: rgb(255, 255, 255);
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 75%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 2px 2px 3px grey;
  z-index: 4;
  height: auto;
}

.textlink {
  border-top: 1px solid black;
}

.logo {
  max-width: 75px;
  height: auto;
}

.logo-image {
  width: 3em;
  border-radius: 50%;
}

.leftDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rightDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navlink {
  font-family: 'Overpass', sans-serif;
  color: rgb(0, 0, 0);
  font-size: 1.5em;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  text-decoration: none;
}

.navlink:hover {
  transition: transform .1s;
  transform: scale(1.25);}

.link--logo-image {
  width: 1.5em;
  filter: grayscale(100%);
}

.mail {
  text-align: center;
  font-size: 2em;
}

.left {
  border-right: 0.05em solid rgb(6, 165, 6);
}

h1 {
  font-size: 0.5em;
  padding-left: 1em;
  color: rgb(51, 51, 51);
}



@media only screen and (max-width: 1200px) {


  .headerStyle {
    width: 97%;

  }


  .navlink {
    font-size: 1.4em;
    margin: 0.5em;
  }

}

@media only screen and (max-width: 450px) {
  .headerStyle {
    justify-content: space-between;
  }


  .navlink {
    font-size: 20px;
    margin: 4px;
  }

}