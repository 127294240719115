@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

.aboutwrapper {
  font-family: 'Overpass', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.aboutwrapper>.coverart {
  margin-top: 5em;
  max-width: 60%;

  border-radius: 5px;
  box-shadow: 1px 1px 5px  rgb(73, 73, 73);

}


.textwrapper>p {
  font-size: 14px;
}

.textwrapper {
  width: 50%;
}

.navHashLink {
  text-decoration: none;
  font-weight: bold;
}



.techSkillsDiv {
  max-width: 75%;
  border-left: 1px solid black;
  border-right: 1px solid black;
}


@media only screen and (max-width: 1200px) {
  .textwrapper {
    width: 95%;
  }

  .aboutwrapper>.coverart {
    margin-top: 3em;
    max-width: 80%;

  }

  .textwrapper>p {
    font-size: 14px;
  }


}