.projectScreen {
  max-width: 60%;
  margin-right: 1em;
  margin-bottom: 1em;
  border-radius: 15px;
  box-shadow: 2px 2px 35px 1px gray;
}

.projectDiv {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: 1em;
  max-width: 75%;
  background-color: white;
  border: 2px solid black;
  box-shadow: 7px 7px black;
  position: relative;
}

.projectDiv:hover {
  transition: transform .2s;
  transform: scale(1.1);
}

.buttonPrive {
  white-space: nowrap;
  padding: 0.7em;
  font-weight: 900;
  text-decoration: none;
  color: black;
  margin-right: 1em;
  box-shadow: 3px 3px 3.5px rgba(0, 0, 0, 0.0);
}

h4 {
  font-size: 1.5em;
}

.titlelink {
  text-align: center;
  text-decoration: none;
  color: rgb(60, 60, 60);
}

.titlelink:hover {
  text-decoration: underline;
  transition: transform .1s;
  transform: scale(1.05);
}

p {
  font-size: 15px;
}


.button {
  white-space: nowrap;
  padding: 0.7em;
  font-weight: 900;
  text-decoration: none;
  color: rgb(56, 116, 203);
  margin-right: 1em;
  box-shadow: 3px 3px 3.5px rgba(0, 0, 0, 0.0);
}

.button:hover {
  border-bottom: 2px solid orange;
  box-shadow: 3px 3px 3.5px rgba(0, 0, 0, 0.712);
}

.buttonGroup {
  margin-top: 1em;
}

.contentBlock {
  display: flex;
  flex-direction: row;
  letter-spacing: 2px;
  line-height: 1.1;
}

.right-handside-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


@media only screen and (max-width: 1200px) {
  .contentBlock {
    display: flex;
    flex-direction: column;
  }

  .buttonGroup {
    display: flex;
    flex-direction: row;
    margin-top: 0em;
  }

  .button {
    width: 50%;
    border-radius: 10px;
    font-size: 90%;
    padding: 20px;
    border: 2px solid rgb(56, 116, 203);
  }

  .projectScreen {
    max-width: 100%;
  }

  .projectDiv {
    max-width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .buttonGroup {
    display: flex;
    flex-direction: row;
    margin-top: 0em;
  }

  .button {
    width: 45%;
    border-radius: 10px;
    padding: 10px;
    border: 2px solid rgb(56, 116, 203);
    font-size: 10px;
    padding: 15px;
  }

  p {
    font-size: 9px;
  }

  h3 {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }

  h4 {
    font-size: 12px;
  }
}