@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

body {
  font-family: 'Overpass', sans-serif;
}

.landingPage {
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(255, 255, 255);
  color: rgb(60, 60, 60);
  letter-spacing: 2.5px;
  line-height: 1.5;
}

p {
  font-size: 19px;
}

.avatar {
  display: flex;
  justify-content: space-evenly;
  padding: 1em;
  margin-left: 1em;
  max-width: 85%;
  margin: 1em;
}

.projectsMap {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;

}

.project {
  align-self: center;
  max-width: 80%;
  margin-bottom: 2em;
  padding: 1em;
}

.avatar-image {
  align-self: center;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  padding: 0.6em;
  box-shadow: 3px 3px 1px 1px rgb(73, 73, 73);
  /* border-top: 10px solid rgb(0, 0, 0);
  border-bottom: 10px solid rgb(0, 0, 0);
  border-left: 1px solid rgb(0, 0, 0);
  border-right: 1px solid rgb(0, 0, 0); */
  border-end-end-radius: 15px;
  height: 350px;
}

.avatar-image:hover {
  transition: transform .2s;
  transform: scale(1.1);
  box-shadow: 10px 10px 10px rgb(126, 126, 126);
}


section {
  background-color: rgb(255, 255, 255);
  color: rgb(60, 60, 60);
}

#section1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.latestProjectsH3 {
  margin: auto;
  color: black;
  max-width: 80%;
  font-size: 2em;
  text-decoration: underline rgb(196, 0, 0);
  text-underline-offset: 10px;
  text-align: left;
}

.avatar-text {
  max-width: 45%;
  font-family: 'Overpass', sans-serif;
}

.avatar-text>p>a {
  text-decoration: none;
  letter-spacing: 3px;
  font-weight:600;
  color: rgb(6, 136, 211);
}

#section2 {
  background-color: rgb(255, 255, 255);
}

#section3 {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.skill-image {
  height: 3em;
  -webkit-filter: grayscale(1);
  /* Webkit */
  filter: gray;
  /* IE6-9 */
  filter: grayscale(1);
  /* W3C */
}

#section0>.coverart {
  -webkit-filter: grayscale(1);
  /* Webkit */
  filter: gray;
  /* IE6-9 */
  filter: grayscale(1);
  /* W3C */
}

.skills-wrap:hover {
  box-shadow: 10px 10px 10px rgb(126, 126, 126);
  transition: transform .1s;
  transform: scale(1.05);
}

.skills-wrap {
  text-align: center;
  margin: 2em;
  margin-right: 5em;
  border-radius: 5px;
  padding: 3px;
}

.techSkillsDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 70%;
  justify-content: center;
  align-items: center;
}

#section0 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1200px) {


  .avatar {
    max-width: 100%;
  }

  .avatar-text {
    max-width: 80%;
  }

  .avatar-text>h2 {
    text-align: center;

  }

  .avatar-text>p {
    font-size: 1.1em;
  }

  .avatar-image {
    max-width: 35%;
    align-self: center;
    height: auto;
    margin-right: 35px;
  }

  .aboutP {
    margin: 0em;
  }

  .skill-image {
    height: 3em;

  }

  .techSkillsDiv {
    max-width: 100%;
  }

  .skills-wrap {
    margin: 1em;
  }

  .coverart {
    max-width: 102%;
  }

  .latestProjectsH3 {
    text-align: center;
    max-width: 100%;
    font-size: 1.5em;
  }

}

@media only screen and (max-width: 800px) {

  .avatar {
    display: flex;
    flex-direction: column;
  }

  .avatar-text {
    margin-top: 0em;
    max-width: 100%;
  }

  .avatar-image {
    margin-top: 2em;
    max-width: 55%;
    margin-right: 0px;
    max-height: 30vh;
  }
}